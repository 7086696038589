export default [
    {
        tanggal:"19/01/2023 11:28",
        judul:"Edit profil perusahaan",
        pembuat_pertanyaan:"Eka Bima Sitorus",
        provinsi:"Bengkulu",
        kabkot:"Kabupaten Mukomuko",
        industri:"PT. Agro Muko - Mukomuko POM"
    },
  ];
  