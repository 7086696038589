<template>
  <div>
      <h1
      class="display-3 d-flex align-items-center font-weight-light overflow-hidden position-absolute text-white"
      style="height: 66px; margin: 0 0 0 30px; top: 0; z-index: 1"
    >
      <h6 class="h2 text-white d-inline-block mb-0">
        <i class="ni ni-collection"></i> Bantuan Sparing
      </h6>
    </h1>
    
    <base-header class="pb-6">
    <b-row>
      <b-col xl="11" md="11">
        <b-card  style="height: 130px;">
          <h5>Cari Berdasarkan</h5>
          <div class="d-flex justify-content-start mb-4">
            <base-input class="m-0 mr-4">
                <el-select filterable placeholder="Jenis Pertanyaan" v-model="userInput.jenisIndustri">
                  <el-option value="">Semua</el-option>
                  <el-option
                    v-for="option, idx in companyType"
                    :key="idx"
                    :label="option.name"
                    :value="option.name"
                  ></el-option>
                </el-select>
              </base-input>

              <base-input class="m-0 mr-4">
                <el-select filterable placeholder="Provinsi" v-model="userInput.provinsi" @change="getCityDepentOnProvince(userInput.provinsi)">
                  <el-option value="">Semua</el-option>
                  <el-option
                    v-for="option, idx in provideSelect.provinsi"
                    :key="idx"
                    :label="option.name"
                    :value="option.id"
                  ></el-option>
                </el-select>
              </base-input>

              <base-input class="m-0 mr-4">
                <el-select filterable placeholder="Kab / Kota" v-model="userInput.kabupaten" >
                  <el-option value="">Semua</el-option>
                  <el-option
                    v-for="option, idx in provideSelect.kabupatenKota"
                    :key="idx"
                    :label="option.name "
                    :value="option.name"
                  ></el-option>
                </el-select>
              </base-input>

              <base-input class="m-0 mr-4">
                <el-select filterable placeholder="Nama Industri" v-model="userInput.namaIndustri">
                  <el-option value="">Semua</el-option>
                  <el-option
                    v-for="option, idx in provideSelect.daftarPerusahaan"
                    :key="idx"
                    :label="option.name "
                    :value="option.name"
                  ></el-option>
                </el-select>
              </base-input>
          </div>
        </b-card>
      </b-col>
    </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col xl="12">
          <b-card>
            <template v-slot:header>
            <b-row>
              <b-col lg="6" cols="7">
                <!-- <h6 class="surtitle">Overview</h6>
                <h5 class="h3 mb-0">Total sales</h5> -->
              </b-col>
            </b-row>
            </template>
            <BantuanSparing/>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import API from '../../api/base_url'
import { Select, Option } from "element-ui";
import BaseHeader from "@/components/BaseHeader";
import DataSparingChart from "@/components/Charts/DataSparingChart.vue";
import BantuanSparing from '../Tables/BantuanSparingTable.vue';



export default {
components: {
  BaseHeader,
  DataSparingChart,
  [Select.name]: Select,
  [Option.name]: Option,
  BantuanSparing
},
mounted() {
  this.getSelectFormValue()
},
data() {
  return {
    provideSelect: {
      provinsi:[],
      kabupatenKota:[],
      daftarPerusahaan:[],
      tmp:[],
      uploadChoosen:null
    },
    userInput: {
      jenisIndustri:"",
      provinsi:"",
      kabupaten:"",
      namaIndustri:"",
      tanggal:""
    },
    companyType: [
      { id: 1, name: "Industri Rayon", color: "#343A40" },
      { id: 2, name: "Industri Pulp/Kertas", color: "#3D9973" },
      { id: 3, name: "Industri Petrokimia Hulu", color: "#17A2B8" },
      { id: 4, name: "Industri Oleokimia Dasar", color: "#007BFF" },
      { id: 5, name: "Industri Minyak Sawit", color: "#FFC107" },
      { id: 6, name: "Pengolahan Minyak Bumi", color: "#80BDFF" },
      { id: 7, name: "Eksplorasi dan Produksi Migas", color: "#5635DC" },
      { id: 8, name: "Pertambangan Emas dan Tembaga", color: "#CE4418" },
      { id: 9, name: "Pertambangan Batu Bara", color: "#E83E8C" },
      { id: 10, name: "Industri Tekstil", color: "#DC3545" },
      { id: 11, name: "Pertambangan Nikel", color: "#28A745" },
      { id: 12, name: "Kawasan Industri", color: "#6AD182" },
    ],
  };
},
methods: {
   getSelectFormValue() {
      API.get("region/provinces")
        .then(({ data: content }) => {
          this.provideSelect.provinsi = content.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCityDepentOnProvince(data) {
      this.provideSelect.kabupatenKota = [];
      this.userInput.kabupaten = ""
      API.get(`region/regencies/province/${data}`)
        .then(({ data: content }) => {
          this.provideSelect.kabupatenKota = content.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
},
};
</script>


<style lang="scss">
#dashboard {
.m-0 {
  .form-group {
    margin: 0;
    .valid-feedback {
      margin: 0;
    }
  }
}
}
.header-desc {
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
color: #29664a;
font-weight: 600;
}
.bmal {
font-size: 12px;
}
</style>

  
  